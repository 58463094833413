import { SOLANA_CHAIN_ID } from 'cb-wallet-data/stores/Collection/constants';

type Props = {
  chainId?: string;
  tokenType?: string;
};

const solanaTokenType = 'SOLANASPL';

export function useIsSolanaCollection({ chainId, tokenType }: Props) {
  if (chainId && tokenType) {
    return chainId === SOLANA_CHAIN_ID && tokenType === solanaTokenType;
  }

  return false;
}
