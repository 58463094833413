import { CollectionDetails } from 'cb-wallet-data/stores/Collection/models/CollectionDetails';
import {
  CollectionDetailsParams,
  useCollectionDetails,
} from 'cb-wallet-data/stores/Explore/api/useCollectionDetails';

export function useGetCollectionDetails({ contractAddress, chainId }: CollectionDetailsParams) {
  const { data, isLoading, isFetching, isRefetching } = useCollectionDetails({
    contractAddress,
    chainId,
  });
  return {
    isLoading,
    isFetching,
    data: data && new CollectionDetails(data),
    isRefetching,
  };
}
