import { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';

import { FETCH_REFRESH_KEY, refreshCollectibles } from '../api';

/**
 * Fetches, caches, and polls collectibles from the Coinbase API.
 *
 * @returns The UseQueryResult containing a Collections object.
 */
export function useRefreshCollectible(
  contractAddress: string,
  tokenId: string,
  chainId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): UseQueryResult<any> {
  return useQuery(
    [FETCH_REFRESH_KEY, contractAddress, tokenId],
    async () => {
      return refreshCollectibles(tokenId, contractAddress, chainId);
    },
    {
      suspense: false,
      useErrorBoundary: false,
      refetchInterval: false,
      notifyOnChangeProps: ['data'],
      retry: false, // prevent refetching on error
      // below are to prevent "auto-fetching" and only allow for manual-refetching
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: false,
    },
  );
}
