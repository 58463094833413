import { useEffect, useMemo, useState } from 'react';
import { EthereumWalletConfiguration } from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { ETHEREUM_PREFIX } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { SolanaWalletConfiguration } from 'cb-wallet-data/chains/AccountBased/Solana/config';
import { SOLANA_PREFIX } from 'cb-wallet-data/chains/AccountBased/Solana/utils/chain';
import { useCurrencyFormatter } from 'cb-wallet-data/CurrencyFormatter/hooks/useCurrencyFormatter';
import { useIsSolanaCollection } from 'cb-wallet-data/hooks/Collections/useIsSolanaCollection';
import { useRefreshCollectible } from 'cb-wallet-data/stores/Collection/hooks/useRefreshCollectible';
import { useTokenDetails } from 'cb-wallet-data/stores/Collection/hooks/useTokenDetails';
import { ExtendedCollectible } from 'cb-wallet-data/stores/Collection/types';
import { fetchDomains } from 'cb-wallet-data/stores/DecentralizedID/hooks/usePublicProfileByAddress';
import { useGetCollectionDetails } from 'cb-wallet-data/stores/Explore/hooks/useGetCollectionDetails';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { abridgeAddress } from 'cb-wallet-data/utils/abridgeAddress';

type UseDisplayNftCollectibleProps = {
  contractAddress: string;
  tokenId: string;
  chainId: string;
};

export function useDisplayNftCollectible({
  contractAddress,
  tokenId,
  chainId,
}: UseDisplayNftCollectibleProps) {
  const { data: token, refetch: refetchTokenDetails } = useTokenDetails({
    contractAddress,
    tokenId,
    chainId,
    includeFloorPrice: true,
  });

  const { data: collection } = useGetCollectionDetails({
    contractAddress,
    chainId,
  });

  const { refetch: refreshCollectible } = useRefreshCollectible(contractAddress, tokenId, chainId);

  const { formatToCrypto } = useCurrencyFormatter();
  const isSolanaCollection = useIsSolanaCollection({ tokenType: token?.tokenType, chainId });
  const [owner, setOwner] = useState(token?.ownerAddress);

  useEffect(
    function resolveDomain() {
      const domain = async function getDomain() {
        /* istanbul ignore else */
        if (token?.ownerAddress) {
          const [ownerDomain] = await fetchDomains([token.ownerAddress]);
          const abridgedAddress = abridgeAddress(ownerDomain || token?.ownerAddress);
          setOwner(abridgedAddress);
        }
      };
      domain();
    },
    [token?.ownerAddress],
  );

  return useMemo(() => {
    const chainPrefix = isSolanaCollection ? SOLANA_PREFIX : ETHEREUM_PREFIX;
    const network = Network.fromChainId({ chainId: BigInt(chainId), chainPrefix });
    const networkName = network.asChain()?.displayName ?? '';
    const currencyCode = isSolanaCollection
      ? SolanaWalletConfiguration.currencyCode
      : EthereumWalletConfiguration.currencyCode;
    const decimals = isSolanaCollection
      ? SolanaWalletConfiguration.decimals
      : EthereumWalletConfiguration.decimals;

    const attributes = token?.attributes?.map((attribute) => ({
      category: attribute.attributeName ?? '',
      attribute: attribute.attributeValue ?? '',
    }));
    const floorPriceAmount = collection?.floorPrices?.[0]?.floorPrice?.amount;

    let floorPrice;
    if (floorPriceAmount) {
      floorPrice = formatToCrypto({
        currencyCode,
        decimals,
        value: BigInt(floorPriceAmount),
        includeCode: false,
        overridePrivacy: true,
        shouldDisplayDustAmount: false,
      })?.replace(/,/g, '');
    }

    let volumeWeekInWei;
    /* istanbul ignore else */
    if (collection?.ethVolumeWeek) {
      const volume = collection?.ethVolumeWeek.split('.')[0];
      volumeWeekInWei = formatToCrypto({
        currencyCode,
        decimals,
        value: BigInt(volume),
        maxDisplayDecimals: 4n,
        includeCode: false,
        overridePrivacy: true,
      })?.replace(/,/g, '');
    }

    const cachedTokenImage =
      token?.cachedImageUrl && Object.hasOwn(token?.cachedImageUrl, 'originalUrl')
        ? token.cachedImageUrl
        : undefined;

    const cachedTokenAnimation =
      token?.cachedAnimationUrl && Object.hasOwn(token?.cachedAnimationUrl, 'originalUrl')
        ? token.cachedAnimationUrl
        : undefined;

    const collectible: ExtendedCollectible = {
      ...token!,
      chainId,
    };

    return {
      refetchTokenDetails,
      refreshCollectible,
      collectible,
      token: {
        attributes,
        cachedTokenImage,
        cachedTokenAnimation,
        description: token?.description,
        totalTokens: token?.tokenCount,
        name: token?.name,
        owner,
        links: token?.marketplaceUrls,
        isSpam: token?.spam,
      },
      collection: {
        network: networkName,
        networkImg: network.asChain()?.chainImageUrl,
        name: token?.collectionName,
        cachedCollectionImage: collection?.cachedCollectionImage,
        totalTokens: collection?.totalTokens,
        ownerCount: collection?.ownerCount,
        verified: collection?.verified,
        volumeWeekInWei,
        floorPrice,
        contractAddress: collection?.contractAddress,
      },
    };
  }, [
    isSolanaCollection,
    chainId,
    token,
    collection?.floorPrices,
    collection?.ethVolumeWeek,
    collection?.cachedCollectionImage,
    collection?.totalTokens,
    collection?.ownerCount,
    collection?.verified,
    collection?.contractAddress,
    owner,
    refetchTokenDetails,
    refreshCollectible,
    formatToCrypto,
  ]);
}
