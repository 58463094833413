import { useMemo } from 'react';
import { useGetAccountById } from 'cb-wallet-data/stores/Accounts/hooks/useGetAccountById';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';

import { useWalletProvidersMap } from './useWalletProviders';

export function useGetProviderByWallet(wallet: Wallet | undefined) {
  const getAccountById = useGetAccountById();
  const walletProviderMap = useWalletProvidersMap();

  return useMemo(() => {
    if (!wallet) return undefined;

    const account = getAccountById(wallet.accountId);

    if (!account?.provider) return undefined;

    return walletProviderMap[account.provider];
  }, [getAccountById, wallet, walletProviderMap]);
}
