import { useMemo } from 'react';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';

import { useWalletsForWalletGroupId } from './useWalletsForWalletGroupId';

type FeeWalletIdentifiableTx = {
  blockchain?: Blockchain;
  network?: Network;
  feeCurrencyCode?: CurrencyCode;
};

export function getFeeWallet<T extends FeeWalletIdentifiableTx>(
  wallets: Wallet[],
  { blockchain, feeCurrencyCode, network }: T,
): Wallet | undefined {
  if (!blockchain || !feeCurrencyCode || !network) {
    return undefined;
  }

  return wallets.find(
    (wallet) =>
      Network.isEqual(wallet.network, network) &&
      Blockchain.isEqual(wallet.blockchain, blockchain) &&
      CurrencyCode.isEqual(wallet.currencyCode, feeCurrencyCode) &&
      wallet.isBaseAsset,
  );
}
/**
 * Returns the right fee wallet for an unsigned transaction.
 *
 * @param tx
 * @returns A single Wallet matching the blockchain, feeCurrencyCode and network parameters, or undefined if none found.
 */
export function useTxFeeWallet<T extends FeeWalletIdentifiableTx>(
  walletGroupId: string | undefined,
  { blockchain, feeCurrencyCode, network } = {} as T,
) {
  const wallets = useWalletsForWalletGroupId(walletGroupId);

  return useMemo(() => {
    if (!wallets) {
      return undefined;
    }

    return getFeeWallet(wallets, { blockchain, feeCurrencyCode, network });
  }, [blockchain, feeCurrencyCode, network, wallets]);
}
