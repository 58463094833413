import {
  CachedAnimatedImage,
  CollectionImage,
  CurrentOwners,
  Token,
  TokensPreviewAttribute,
} from 'cb-wallet-data/stores/Collection/types';

export class CollectionToken {
  attributes: TokensPreviewAttribute[];
  cachedCollectionImage?: CollectionImage;
  contractAddress: string;
  collectionName: string;
  collectionSymbol: string;
  tokenId: string;
  tokenType: string;
  name: string;
  description: string;
  imageUrl: string;
  openseaLink: string;
  paymentCurrency: string;
  lastSoldPrice: string;
  tokenMinters: CurrentOwners;
  currentOwners: CurrentOwners;
  metadataStatus?: number;
  cachedImageUrl?: CollectionImage;
  cachedAnimationUrl?: CachedAnimatedImage;
  hasUnretrievableMetadata?: boolean;
  constructor(token: Token) {
    this.attributes = token.attributes;
    this.cachedCollectionImage = token.cachedCollectionImage;
    this.contractAddress = token.contractAddress;
    this.collectionName = token.collectionName;
    this.collectionSymbol = token.collectionSymbol;
    this.tokenId = token.tokenId;
    this.tokenType = token.tokenType;
    this.name = token.name;
    this.description = token.description;
    this.imageUrl = token.imageUrl;
    this.openseaLink = token.openseaLink;
    this.paymentCurrency = token.paymentCurrency;
    this.lastSoldPrice = token.lastSoldPrice;
    this.tokenMinters = token.tokenMinters;
    this.currentOwners = token.currentOwners;
    this.metadataStatus = token.metadataStatus;
    this.cachedImageUrl = token.cachedImageUrl;
    this.cachedAnimationUrl = token.cachedAnimationUrl;
    this.hasUnretrievableMetadata = token.hasUnretrievableMetadata;
  }
}
