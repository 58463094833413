import { useMemo } from 'react';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { CollectionDetailsResponse } from 'cb-wallet-data/stores/Collection/types';
import { getJSON } from 'cb-wallet-http/fetchJSON';

export const COLLECTION_DETAILS_QUERY_KEY = 'collectibles/getAggregatedCollectionDetails';

export type CollectionDetailsParams = {
  contractAddress: string;
  chainId: string;
};

export async function fetchCollectionDetails({
  contractAddress,
  chainId,
}: CollectionDetailsParams): Promise<CollectionDetailsResponse> {
  const params = {
    contractAddress,
    chainId,
  };
  const { result } = await getJSON<{ result: CollectionDetailsResponse }>(
    COLLECTION_DETAILS_QUERY_KEY,
    params,
    { apiVersion: 2 },
  );
  return result;
}

export function useCollectionDetails({ contractAddress, chainId }: CollectionDetailsParams) {
  const { data, isLoading, refetch, isFetching, isRefetching } = useQuery(
    [COLLECTION_DETAILS_QUERY_KEY, contractAddress, chainId],
    async () => fetchCollectionDetails({ contractAddress, chainId }),
  );

  return useMemo(
    () => ({
      data,
      isLoading,
      isFetching,
      refetch,
      isRefetching,
    }),
    [data, isLoading, refetch, isFetching, isRefetching],
  );
}
