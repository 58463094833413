import { CollectionToken } from 'cb-wallet-data/stores/Collection/models/CollectionToken';
import {
  AttributeCategory,
  CachedMediaType,
  CollectionDetailsResponse,
  FloorPrice,
  MintSource,
} from 'cb-wallet-data/stores/Collection/types';

export class CollectionDetails {
  attributeCategories: AttributeCategory[];
  cachedCollectionImage: CachedMediaType;
  chainId: string;
  collectionDescription?: string;
  collectionName: string;
  coinbaseLink: string;
  contractAddress: string;
  contractOwner?: string;
  contractType: string;
  creatorAddress?: string;
  discordUrl?: string;
  ethAveragePriceWeek?: string;
  etherscanLink: string;
  ethVolumeOneDay?: string;
  ethVolumeOneDayTrend?: string;
  ethVolumeOneWeekTrend?: string;
  ethVolumeWeek: string;
  floorPrices: FloorPrice[];
  marketplaceAdminVerified?: boolean;
  nsfw: boolean;
  ownerCount: string;
  raribleLink: string;
  tokensPreview: CollectionToken[];
  totalTokens: string;
  tradeVolume: string;
  twitterUrl?: string;
  verified: boolean;
  mintSource?: MintSource;
  constructor(collectionDetailsResponse: CollectionDetailsResponse) {
    this.attributeCategories = collectionDetailsResponse.attributeCategories;
    this.cachedCollectionImage = {
      originalUrl: collectionDetailsResponse.cachedCollectionImage.originalUrl,
      cachedPath: collectionDetailsResponse.cachedCollectionImage.cachedPath,
      mimeType: collectionDetailsResponse.cachedCollectionImage.mimeType,
      itemSizeBytes: collectionDetailsResponse.cachedCollectionImage.itemSizeBytes,
    };
    this.chainId = collectionDetailsResponse.chainId;
    this.collectionDescription = collectionDetailsResponse.collectionDescription;
    this.collectionName = collectionDetailsResponse.collectionName;
    this.coinbaseLink = collectionDetailsResponse.coinbaseLink;
    this.contractAddress = collectionDetailsResponse.contractAddress;
    this.contractOwner = collectionDetailsResponse.contractOwner;
    this.contractType = collectionDetailsResponse.contractType;
    this.creatorAddress = collectionDetailsResponse.creatorAddress;
    this.discordUrl = collectionDetailsResponse.discordUrl;
    this.ethAveragePriceWeek = collectionDetailsResponse.ethVolumeOneDay;
    this.etherscanLink = collectionDetailsResponse.etherscanLink;
    this.ethVolumeOneDay = collectionDetailsResponse.ethVolumeOneDay;
    this.ethVolumeOneDayTrend = collectionDetailsResponse.ethVolumeOneDayTrend;
    this.ethVolumeOneWeekTrend = collectionDetailsResponse.ethVolumeOneWeekTrend;
    this.ethVolumeWeek = collectionDetailsResponse.ethVolumeWeek;
    this.floorPrices = collectionDetailsResponse.floorPrices;
    this.marketplaceAdminVerified = collectionDetailsResponse.marketplaceAdminVerified;
    this.nsfw = collectionDetailsResponse.nsfw;
    this.ownerCount = collectionDetailsResponse.ownerCount;
    this.raribleLink = collectionDetailsResponse.raribleLink;
    this.tokensPreview = collectionDetailsResponse.tokensPreview.map(
      (token) => new CollectionToken(token),
    );
    this.totalTokens = collectionDetailsResponse.totalTokens;
    this.tradeVolume = collectionDetailsResponse.tradeVolume;
    this.twitterUrl = collectionDetailsResponse.twitterUrl;
    this.verified = collectionDetailsResponse.verified;
    this.mintSource = collectionDetailsResponse.mintSource;
  }
}
